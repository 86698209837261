import { useMemo } from 'react';

import { makeVar, useReactiveVar } from '@apollo/client';

import {
  getReactiveLocal,
  setReactiveLocal,
  usePreviewSettingsVar,
} from '../api/apollo/reactiveVars';
import { TermKey } from '../api/gqlEnums';
import { getMarkupModePermissionLabel } from '../components/TeamRoute/RolesRoute/table/permissions';
import useMemoWrapper from '../components/useMemoWrapper';
import { NS_OWNER_COSTS_V2 } from '../features';
import { CostDisplay, MarkupMode, PermissionResource } from '../generated/graphql';
import { useHasFeature } from '../hooks/useHasFeature';

import { UsePermissions } from './permissions/types';
import usePermissions from './permissions/usePermissions';
import { useProjectID } from './routes/params';
import { getProjectIdFromUrl } from './url';

export const checkCostModeIncludesMarkups = (costMode: CostMode) =>
  costMode.markupMode !== MarkupMode.NO_MARKUPS;

export const getMarkupModeLabel = (markupMode: MarkupMode, t: TermStore) => {
  switch (markupMode) {
    case MarkupMode.ALLOCATED_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.ALLOCATED_MARKUPS_VIEW, t);
    case MarkupMode.SEPARATED_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.SEPARATED_MARKUPS_VIEW, t);
    case MarkupMode.NO_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.NO_MARKUPS_VIEW, t);
    default:
      return getMarkupModePermissionLabel(PermissionResource.ALLOCATED_MARKUPS_VIEW, t);
  }
};

export const useAvailableCostModes = (projectID?: UUID) => {
  const { canView, loading } = usePermissions({ projectID });
  return useMemoWrapper(getAvailableCostModes, canView, loading);
};

const getAvailableCostModes = (
  canView: ReturnType<typeof usePermissions>['canView'],
  loading: boolean
) => {
  const availableCostDisplays: CostDisplay[] = [];
  const availableMarkupModes: MarkupMode[] = [];
  if (loading) return { availableCostDisplays, availableMarkupModes, loading };

  availableCostDisplays.push(CostDisplay.HIDE_COSTS);
  if (canView(PermissionResource.DISPLAY_COSTS)) availableCostDisplays.push(CostDisplay.SHOW_COSTS);

  if (canView(PermissionResource.ALLOCATED_MARKUPS_VIEW))
    availableMarkupModes.push(MarkupMode.ALLOCATED_MARKUPS);
  if (canView(PermissionResource.SEPARATED_MARKUPS_VIEW))
    availableMarkupModes.push(MarkupMode.SEPARATED_MARKUPS);
  if (canView(PermissionResource.NO_MARKUPS_VIEW)) availableMarkupModes.push(MarkupMode.NO_MARKUPS);

  return {
    availableCostDisplays,
    availableMarkupModes,
    loading,
  };
};

// ToDo: Update with correct terms
export const getProjectCostsDisplayLabel = (costMode: CostMode, t: TermStore) => {
  if (costMode.costDisplay) {
    return t.sentenceCase(TermKey.MARKUP);
  }
  return t.sentenceCase(TermKey.ACCEPTED_CHANGES);
};

// NEW COST MODE CODE BELOW

const LOCAL_STORAGE_COST_MODES = 'PROJECTS_COST_MODES';
type ProjectsCostModes = Record<UUID, CostMode>;

const fetchLocalStorageCostModes = () =>
  getReactiveLocal<ProjectsCostModes>(LOCAL_STORAGE_COST_MODES, {});

const projectsCostModesVar = makeVar<ProjectsCostModes>(fetchLocalStorageCostModes());

/** @deprecated Use useCostMode() instead */
export const getCostMode = () =>
  projectsCostModesVar()[getProjectIdFromUrl()] || {
    markupMode: MarkupMode.ALLOCATED_MARKUPS,
    costDisplay: CostDisplay.SHOW_COSTS,
    includeOwnerCosts: false,
  };

export const useCostMode = (customProjectID?: UUID) => {
  const currentProjectID = useProjectID();
  const projectID = customProjectID || currentProjectID;
  const permissions = usePermissions({ projectID });
  return useCostModePermissions(permissions);
};

export const useCostModePermissions = (permissions: UsePermissions) => {
  const projectID = useProjectID();
  const projectsCostModes = useReactiveVar(projectsCostModesVar);

  const hasOwnerCostFeature = useHasFeature(NS_OWNER_COSTS_V2);
  const { canView, loading } = permissions;

  const canViewOwnerCosts = canView(PermissionResource.OWNER_COSTS);

  let markupMode = MarkupMode.ALLOCATED_MARKUPS;
  let costDisplay = CostDisplay.SHOW_COSTS;
  let includeOwnerCosts = canViewOwnerCosts;

  if (projectID && projectsCostModes[projectID]) {
    markupMode = projectsCostModes[projectID].markupMode;
    costDisplay = projectsCostModes[projectID].costDisplay ?? CostDisplay.SHOW_COSTS;
    if (hasOwnerCostFeature)
      includeOwnerCosts = projectsCostModes[projectID].includeOwnerCosts ?? false;
  }
  // Update CostMode if you don't have access to the current value
  // NOTE: there are a couple of reasons we need to check permissions in order
  // to change the cost mode: a) when acessing a project for the first time, b)
  // when previewing a user/role, and c) your permissions have been changed
  // since the last time you accessed the project. In the future, we should see
  // if there is a better place to handle this logic, so it doesn't need to be
  // baked into all the places we call useCostMode.
  const { availableCostDisplays, availableMarkupModes } = getAvailableCostModes(canView, loading);
  if (!loading && projectID) {
    if (Boolean(availableMarkupModes.length) && !availableMarkupModes.includes(markupMode))
      markupMode = availableMarkupModes[0];
    if (Boolean(availableCostDisplays.length) && !availableCostDisplays.includes(costDisplay))
      costDisplay = availableCostDisplays[0];
  }

  // check permissions for viewing owner costs
  if (!canViewOwnerCosts && includeOwnerCosts) includeOwnerCosts = false;

  // Update LocalStorage if current value is different that what is stored there (and not in preview mode)
  const preview = usePreviewSettingsVar();
  const inPreviewMode = Boolean(preview.userID || preview.roleID);
  if (
    !loading &&
    !inPreviewMode &&
    projectID &&
    (projectsCostModes[projectID]?.markupMode !== markupMode ||
      projectsCostModes[projectID]?.costDisplay !== costDisplay)
  ) {
    const newProjectsCostModes = {
      ...projectsCostModes,
      [projectID]: { costDisplay, markupMode, includeOwnerCosts },
    };
    setReactiveLocal(projectsCostModesVar, LOCAL_STORAGE_COST_MODES, newProjectsCostModes);
  }

  return useMemo(
    () => ({ markupMode, costDisplay, includeOwnerCosts }),
    [costDisplay, markupMode, includeOwnerCosts]
  );
};

export const updateCostMode = (projectID: UUID, update: Partial<CostMode>) => {
  const projectsCostModes = projectsCostModesVar();
  const prev = projectsCostModes[projectID] ?? {
    markupMode: MarkupMode.ALLOCATED_MARKUPS,
    costDisplay: CostDisplay.SHOW_COSTS,
    includeOwnerCosts: false,
  };
  const newProjectsCostModes = {
    ...projectsCostModes,
    [projectID]: { ...prev, ...update },
  };
  setReactiveLocal(projectsCostModesVar, LOCAL_STORAGE_COST_MODES, newProjectsCostModes);
};
